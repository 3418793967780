import React from "react"
import styled from "styled-components"
import github from "../../images/github.svg"
import linkedin from "../../images/linkedin.svg"

const Footer = styled.footer`
  bottom: auto;
  left: auto;
  padding: 1em 4em 6em;
  position: relative;
  text-align: center;
  width: 100%;
`

const Icons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
const StyledLink = styled.a`
  background-size: cover;
  background-image: url(${props => props.social});
  display: inline-block;
  filter: invert(100%);
  height: 25px;
  margin: 10px;
  width: 25px;
  &:hover,
  &:focus {
    filter: invert(26%) sepia(50%) saturate(2878%) hue-rotate(200deg)
      brightness(120%) contrast(100%);
  }
  span {
    display: none;
  }
`

const HeaderFooter = () => (
  <Footer>
    <Icons>
      <StyledLink
        social={github}
        href="https://github.com/g-dem"
        aria-label="GitHub"
      >
        <span>Github</span>
      </StyledLink>
      <StyledLink
        social={linkedin}
        href="https://it.linkedin.com/in/giovannidemizio"
        aria-label="Linkedin"
      >
        <span>Linkedin</span>
      </StyledLink>
    </Icons>
  </Footer>
)
export default HeaderFooter;