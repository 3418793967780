import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import styled from "styled-components"

import HireMe from "../atoms/HireMe"
import overlay from "../../images/overlay.png"
import img from "../../images/Programming.jpg"
import Footer from "./headerFooter"

const About = styled.div`
`
const Aside = styled.header`
background-attachment: scroll;
background-color: #000000;
background-image: url(${overlay}), url(${img});
background-position: 0 0, 50%;
background-size: auto, cover;
color: #ffffff;
display:block;
left: auto;
padding: 3em 2em;
position: relative;
text-align: center;
top: auto;
width: 100%;
height: 100vh;
@media screen and (min-width: 768px) {
  left: 0;
  position: fixed;
  top: 0;
  width: 25%;
  max-width: 400px;
}
.block {
  font-size: 1.4em;
  line-height: 1.5;
  text-align: center;
  &:first-child {
    font-weight: bold;
    margin-bottom: 2em;
  }
}
`
const Container = styled.div`
align-items: center;  
display:flex;
flex-direction:column;
height: 100%;
justify-content: space-between;
`

const Sidebar = () => {
  const data = useStaticQuery(graphql`query allSidebarBlocks {
  allSidebarBlockJson {
    edges {
      node {
				key,
        blocks
      }
    }
  }
}
`)
  return <Aside>
    <Container>
      <About>
        {data.allSidebarBlockJson.edges.map(({node: block}, i) => (
          <div key={i} className="block">
            {block.blocks.map((b, i) => (<div key={i}>{b}</div>))}
          </div>
        ))}
      </About>
      <div className="block">
        <HireMe />
      </div>
      <Footer />
    </Container>
  </Aside>
}
export default Sidebar