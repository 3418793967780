import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import "./layout.css"
import Sidebar from "../components/organisms/Sidebar"
import Footer from "../components/organisms/footer"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const MainContainer = styled.div`
  padding: 1rem;
  @media screen and (min-width: 768px) {
    margin-left: 30%;
    max-width: 54em;
    padding: 4em 4em 4em;
  }
`

const Layout = ({ lang, children }) => {
  return (
    <Container>
      <Sidebar lang={lang} />
      <MainContainer>
        {children}
        <Footer />
      </MainContainer>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
