import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(props => <Link {...props} />)`
  background: rgb(0, 116, 217);
  border-color: initial;
  border-image: initial;
  border-radius: 3px;
  border-style: none;
  border-width: initial;
  cursor: pointer;
  color: rgb(255, 255, 255);
  display: block;
  font-weight: bold;
  -webkit-appearance: none;
  max-width: 300px;
  padding: 0.7rem 2.5rem;
  user-select: none;
`
const HireMe = () => (
  <StyledLink to="/#contact">May I help you?</StyledLink>
)
export default HireMe