import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = () => (
  <footer style={{textAlign: "right"}}>
    <small>
      © 2008 - {new Date().getFullYear()} Giovanni De Mizio - This site is built with
      {` `}
      <OutboundLink href="https://www.gatsbyjs.org">Gatsby</OutboundLink>
    </small>
  </footer>
)

export default Footer